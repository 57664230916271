<template>
   <div id="apply-job-list">
       <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
           <div class="content">
               <div class="empty" v-if="!isRefresh && !dataList.length">-- 已經全部加載 --</div>
               <router-link :to="`/job/details/${item.jobId}`" class="job-item" v-for="(item, index) in dataList" :key="index">
                  <div class="row-title">
                     <h1 class="title" v-text="item.jobInfo.title"></h1>
                  </div>
                  <div class="clinic-info">
                     <img :src="item.jobInfo.clinicLogo" :alt="item.jobInfo.clinicName" class="logo">
                     <h2 class="name" v-text="item.jobInfo.clinicName"></h2>
                  </div>
                  <div class="other-infos">
                     <div class="row">
                        <span class="hint">投遞時間：</span>
                        <div class="value" v-text="item.createTime"></div>
                     </div>
                     <div class="row">
                        <span class="hint">是否查閱：</span>
                        <div class="value" v-text="item.lookOver == true ? '是':'否'"></div>
                     </div>
                  </div>

              </router-link>
           </div>
       </van-pull-refresh>
   </div>
</template>

<script>
   import apply_job from '@/views/mixins/apply_job'
   export default {
      mixins:[
         apply_job,
      ],
      data(){
         return {
            pageSize: 10,
            pageNo: 1,
            total: 0,
            dataList: [],
            isRefresh: true,
            area1List: [],
            area2List: [],
            area2Map: {},
            keyword: '',
            areaLevel1Id: undefined,
            areaLevel2Id: undefined,
         }
      },
      mounted(){
         var hash = this.$route.hash
         hash = hash.replace('#', '')
         var list = hash.split('&')
         var key = list[0].split('=')[0]
         var value = list[0].split('=')[1] / 1
         if(key == 'page') {
            if(! isNaN(value)) {
                  this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
            }
         }
         this.onRefresh()
      },
      methods: {
         onPageChanged: function(pageNo){
            var path = this.$route.fullPath
            var hash = this.$route.hash
            if(hash && path.indexOf(hash) != -1) {
                  path = path.replace(hash, '')
            }
            this.$router.push({path:  path+'#page='+pageNo},(e)=>{});
         },
         onRefresh(){
            if(this.total > 0 && this.pageNo > 0) {
               var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
               if(maxPage > 0 && maxPage < this.pageNo) {
                  var path = this.$route.fullPath
                  var hash = this.$route.hash
                  if(hash && path.indexOf(hash) != -1) {
                     path = path.replace(hash, '')
                  }
                  this.$router.push({path: `${path}#page=1`},(e)=>{})
                  return
               }
            }
            const params = {
               "clientId": this.$store.getters.clientId,
               "pageNum": this.pageNo,
               "pageSize": this.pageSize,
               "uid": this.$store.getters.clientId
            }
            this.loadData(params, (data, error)=>{
               this.isRefresh = false
               if(error) {
                  this._toast.warning(this, error)
               } else {
                  this.dataList = data.list
                  console.log('this.dataList',this.dataList)
                  this.total = data.total
                  this.visible = this.total > 0
                  var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                  if(maxPage > 0 && maxPage < this.pageNo) {
                     var path = this.$route.fullPath
                     var hash = this.$route.hash
                     if(hash && path.indexOf(hash) != -1) {
                           path = path.replace(hash, '')
                     }
                     this.$router.push({path: `${path}#page=1`},(e)=>{})
                     return
                  }
               }
            })
         },
       }
   }
</script>

<style lang="scss" scoped>
   #apply-job-list {
       width: 100%;
       min-height: 100vh;
       display: flex;
       flex-direction: column;
   }
   #apply-job-list .content {
       flex: 1;
       width: 100%;
       min-height: 100vh;
       position: relative;
       margin: 0px auto;
       margin-top: 40px;
   }

   #apply-job-list .content .empty {
       position: absolute;
       left: 50%;
       transform: translateX(-50%);
       top: 50px;
       z-index: 0;
       color: #9E9E9E;
       font-size: 24px;
   }

   #apply-job-list .job-item {
       position: relative;
       display: block;
       padding: 32px 26px;
       background-color: #fff;
       border-radius: 8px;
       margin-bottom: 24px;
   }

   #apply-job-list .job-item > .row-title {
       display: flex;
       flex-direction: row;
       line-height: 50px;
   }

   #apply-job-list .job-item > .row-title .title {
       color: #231F20;
       font-size: 48px;
       font-weight: bold;
       line-height: 48px;
   }

   #apply-job-list .job-item .job-intro {
       margin-top: 36px;
       display: flex;
       flex-direction: column;
   }

   #apply-job-list .job-item .job-intro .intro-title {
       color: #231F20;
       font-size: 28px;
       line-height: 28px;
   }

   #apply-job-list .job-item .job-intro .intro-content {
       color: #969696;
       font-size: 28px;
       margin-top: 8px;
   }

   #apply-job-list .job-item .job-intro:first-child {
       margin-bottom: 35px;
   }
   
   #apply-job-list .clinic-info {
       display: flex;
       flex-direction: row;
       align-items: center;
       margin-top: 20px;
   }

   #apply-job-list .clinic-info > img {
       display: inline-block;
       width: 50px;
       height: 50px;
       border-radius: 50%;
       padding: 4px;
       object-fit: contain;
       border: 1px solid #f3f3f3;
   }

   #apply-job-list .clinic-info > img[src=""] {
       padding: 0;
       position: relative;
   }

   #apply-job-list .clinic-info > img[src=""]::after {
       content: '';
       width: 50px;
       height: 50px;
       border-radius: 50%;
       background-color: #f1f1f1;
       position: absolute;
       left: 0;
       top: 0;
   }

   #apply-job-list .clinic-info > .name {
       color: #666;
       font-size: 32px;
       font-weight: bold;
       margin-left: 8px;
   }

   #apply-job-list .other-infos {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 28px;
      font-weight: 400;
      line-height: 42px;
   }

   #apply-job-list .other-infos .row {
      display: flex;
      flex-direction: row;
   }
</style>
